import React, { useState, useEffect } from 'react';
import {
    Typography,
    Button,
    Card,
    Spin,
    Alert,
    Divider,
    Tag,
    Space,
    Modal,
    Select,
    Empty,
    Table,
    Input,
    Form,
    message
} from 'antd';
import { PlusOutlined, EyeOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../../../../provider/authProvider';
import LoggedInPagesTemplate from '../../../templates/LoggedInPagesTemplate';
import { useSelector } from "react-redux";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const BrandVoice = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [brandVoices, setBrandVoices] = useState([]);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editForm] = Form.useForm();
    const { backendAxios } = useAuth();
    const user = useSelector((state) => state.user.userInfo);
    const store = user.store;

    // Fetch brand voices
    const fetchBrandVoices = async () => {
        try {
            setLoading(true);
            const response = await backendAxios.get('generative-ai/brand-voices', {
                params: {
                    shop: store.shop
                }
            });
            setBrandVoices(response.data.brandVoices || []);
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to load brand voices');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBrandVoices();
    }, []);

    const handleGenerateBrandVoice = async (values) => {
        try {
            setGenerating(true);
            const response = await backendAxios.post('generative-ai/brand-voice/generate', {
                name: values.name,
                tags: values.tags,
                sourceCopy: values.sourceCopy ? values.sourceCopy.split('\n').filter(line => line.trim()) : [],
            }, {
                params: {
                    shop: store.shop
                }
            });
            await fetchBrandVoices();
            setCreateModalVisible(false);
            editForm.resetFields();
            message.success('Brand voice generated successfully');
        } catch (err) {
            message.error(err.response?.data?.message || 'Failed to generate brand voice');
        } finally {
            setGenerating(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await backendAxios.delete(`generative-ai/brand-voice/${id}`, {
                params: {
                    shop: store.shop
                }
            });
            await fetchBrandVoices();
            message.success('Brand voice deleted successfully');
        } catch (err) {
            message.error(err.response?.data?.message || 'Failed to delete brand voice');
        }
    };

    const handleEdit = (record) => {
        setSelectedVoice(record);
        editForm.setFieldsValue({
            name: record.name,
            tags: record.tags,
            prompt: record.prompt
        });
        setEditModalVisible(true);
    };

    const handleEditSubmit = async (values) => {
        try {
            await backendAxios.put(`generative-ai/brand-voice/${selectedVoice._id}`, {
                ...values,
                shop: store.shop
            });
            message.success('Brand voice updated successfully');
            setEditModalVisible(false);
            editForm.resetFields();
            fetchBrandVoices();
        } catch (err) {
            message.error(err.response?.data?.message || 'Failed to update brand voice');
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (tags) => (
                tags ? tags.split(',').map(tag => (
                    <Tag key={tag.trim()}>{tag.trim()}</Tag>
                )) : null
            )
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => new Date(date).toLocaleDateString()
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => {
                            setSelectedVoice(record);
                            setViewModalVisible(true);
                        }}
                    >
                        View
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => handleDelete(record._id)}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    ];

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return (
            <div style={{ padding: 24 }}>
                <Alert message={error} type="error" showIcon />
            </div>
        );
    }

    return (
        <LoggedInPagesTemplate>
            <div style={{ padding: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
                    <Title level={2}>Brand Voices</Title>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setCreateModalVisible(true)}
                    >
                        Create Brand Voice
                    </Button>
                </div>

                {brandVoices.length > 0 ? (
                    <Table
                        dataSource={brandVoices}
                        columns={columns}
                        rowKey="name"
                    />
                ) : (
                    <Empty
                        description={
                            <div>
                                <Title level={4}>No Brand Voices Created Yet</Title>
                                <Paragraph type="secondary">
                                    Create a brand voice to start generating consistent product descriptions
                                </Paragraph>
                            </div>
                        }
                    />
                )}

                {/* Create Brand Voice Modal */}
                <Modal
                    title="Create Brand Voice"
                    open={createModalVisible}
                    onCancel={() => {
                        setCreateModalVisible(false);
                        editForm.resetFields();
                    }}
                    footer={null}
                    width={800}
                >
                    <Form
                        form={editForm}
                        onFinish={handleGenerateBrandVoice}
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter a name' }]}
                        >
                            <Input placeholder="Enter brand voice name" />
                        </Form.Item>
                        <Form.Item
                            name="tags"
                            label="Tags"
                            help="Separate tags with commas"
                        >
                            <Input placeholder="e.g. professional, friendly, casual" />
                        </Form.Item>
                        <Form.Item
                            name="sourceCopy"
                            label="Source Copy"
                            help="Enter your existing product descriptions or brand content. This will help generate a more accurate brand voice."
                            rules={[{ required: true, message: 'Please enter some source copy' }]}
                        >
                            <ReactQuill
                                theme="snow"
                                style={{ height: '200px', marginBottom: '50px' }}
                                preserveWhitespace={true}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit" loading={generating}>
                                    Generate
                                </Button>
                                <Button onClick={() => {
                                    setCreateModalVisible(false);
                                    editForm.resetFields();
                                }}>
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* View Brand Voice Modal */}
                <Modal
                    title={`Brand Voice: ${selectedVoice?.name}`}
                    open={viewModalVisible}
                    onCancel={() => {
                        setViewModalVisible(false);
                        setSelectedVoice(null);
                    }}
                    footer={[
                        <Button key="close" onClick={() => {
                            setViewModalVisible(false);
                            setSelectedVoice(null);
                        }}>
                            Close
                        </Button>
                    ]}
                    width={800}
                >
                    {selectedVoice && (
                        <div>
                            <div style={{ marginBottom: 16 }}>
                                {selectedVoice.tags && selectedVoice.tags.split(',').map(tag => (
                                    <Tag key={tag.trim()}>{tag.trim()}</Tag>
                                ))}
                            </div>
                            <Card>
                                {selectedVoice.prompt.includes("</p>") ? (
                                    <p dangerouslySetInnerHTML={{ __html: selectedVoice.prompt }} />
                                ) : (
                                    <p style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
                                        {selectedVoice.prompt}
                                    </p>
                                )}
                            </Card>
                        </div>
                    )}
                </Modal>

                {/* Edit Modal */}
                <Modal
                    title="Edit Brand Voice"
                    open={editModalVisible}
                    onCancel={() => {
                        setEditModalVisible(false);
                        editForm.resetFields();
                    }}
                    footer={null}
                    width={800}
                >
                    <Form
                        form={editForm}
                        onFinish={handleEditSubmit}
                        layout="vertical"
                    >
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter a name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="tags"
                            label="Tags"
                            help="Separate tags with commas"
                        >
                            <Input placeholder="e.g. professional, friendly, casual" />
                        </Form.Item>
                        <Form.Item
                            name="prompt"
                            label="Brand Voice Prompt"
                            rules={[{ required: true, message: 'Please enter the brand voice prompt' }]}
                        >
                            <ReactQuill
                                theme="snow"
                                style={{ height: '300px', marginBottom: '50px' }}
                                preserveWhitespace={true}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Update
                                </Button>
                                <Button onClick={() => {
                                    setEditModalVisible(false);
                                    editForm.resetFields();
                                }}>
                                    Cancel
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </LoggedInPagesTemplate>
    );
};

export default BrandVoice; 