import React, { useEffect, useState } from "react";
import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import { Row, Col, Card, Table, Button } from "antd";
import { useAuth } from "../../../provider/authProvider.js";
import { setUser } from "../../../app/userSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardTitle from "../../atoms/Title";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { backendAxios } = useAuth();
  const user = useSelector((state) => state.user.userInfo);
  const [products, setProducts] = useState([]);

  return (
    <LoggedInPagesTemplate>
      <div style={{ padding: "24px" }}>
      </div>
    </LoggedInPagesTemplate>
  );
};

export default Dashboard;
