import React from 'react';
import { Button, Typography } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import VersionItem from '../../atoms/VersionItem/VersionItem';
import './VersionControl.css';

const { Text } = Typography;

const VersionControl = ({ versions, currentVersion, onPublish, onCreateVersion }) => {
    const sortedVersions = [...versions].sort((a, b) => b.version - a.version);
    
    return (
        <div className="version-control">
            <div className="version-header">
                <Text strong>Version Control</Text>
                <div className="version-actions">
                    <Button 
                        type="primary" 
                        icon={<SaveOutlined />}
                        onClick={onCreateVersion}
                    >
                        Create New Version
                    </Button>
                </div>
            </div>
            <div className="version-list">
                {sortedVersions.map((version) => (
                    <VersionItem
                        key={version._id}
                        version={version}
                        isCurrentVersion={version._id === currentVersion._id}
                        onPublish={onPublish}
                    />
                ))}
            </div>
        </div>
    );
};

export default VersionControl; 