import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import TextBlock from '../Blocks/TextBlock';
import ImageBlock from '../Blocks/ImageBlock';
import { useAuth } from '../../../provider/authProvider.js';
const EditorContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  position: relative;
`;

const IframeContainer = styled.div`
  flex: 1;
  position: relative;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const HoverOverlay = styled.div`
  position: absolute;
  background: rgba(75, 107, 251, 0.1);
  border: 2px solid #4B6BFB;
  pointer-events: none;
  z-index: 1000;
  transition: all 0.2s ease;
`;

const ActionButton = styled.button`
  position: absolute;
  background: #4B6BFB;
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1001;
  
  &.top {
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  &.bottom {
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const BlocksContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: all;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: #4B6BFB;
`;

const ErrorMessage = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  background: #ff4444;
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1002;
`;

const SuccessMessage = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 15px 20px;
  background: #44b544;
  color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1002;
`;

const VisualEditor = ({ url, selectedBlock, onUrlChange }) => {
    const iframeRef = useRef(null);
    const [hoverElement, setHoverElement] = useState(null);
    const [overlay, setOverlay] = useState({ top: 0, left: 0, width: 0, height: 0 });
    const [blocks, setBlocks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const { backendAxios } = useAuth();

    const loadContent = async (targetUrl) => {
        if (!targetUrl) return;

        setIsLoading(true);
        setError(null);
        try {
            const proxyUrl = `${process.env.REACT_APP_BACKEND_HOST || 'http://localhost:3001'}proxy/fetch-page?url=${encodeURIComponent(targetUrl)}`;
            const iframe = iframeRef.current;

            if (iframe) {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                iframeDoc.open();

                const response = await backendAxios.get(proxyUrl);
                const html = response.data;

                iframeDoc.write(html);
                iframeDoc.close();
            }
        } catch (error) {
            console.error('Failed to load content:', error);
            setError('Failed to load page content. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadContent(url);
    }, [url, backendAxios]);

    useEffect(() => {
        const handleMessage = async (event) => {
            if (event.data.type === 'hover') {
                setHoverElement(event.data.selector);
                setOverlay(event.data.bounds);
            } else if (event.data.type === 'formSubmit') {
                setIsLoading(true);
                setError(null);
                setSuccess(null);

                try {
                    const response = await backendAxios.post('/proxy/submit', {
                        originalAction: event.data.action,
                        originalMethod: event.data.method,
                        formData: event.data.data,
                        isShopifyPassword: event.data.isShopifyPassword
                    });

                    // Handle the response with potential redirect
                    const { data, html, finalUrl } = response.data;

                    // Update URL if it changed due to redirect
                    if (finalUrl && finalUrl !== url) {
                        onUrlChange?.(finalUrl);
                    }

                    if (event.data.isShopifyPassword) {
                        // For Shopify password form, reload the content
                        await loadContent(finalUrl || url);
                        setSuccess('Store password accepted!');
                    } else if (data) {
                        // Handle JSON response
                        setSuccess('Form submitted successfully!');
                    } else if (html) {
                        // Update iframe with HTML response
                        const iframe = iframeRef.current;
                        if (iframe) {
                            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                            iframeDoc.open();
                            iframeDoc.write(html);
                            iframeDoc.close();
                        }
                    }
                } catch (error) {
                    console.error('Form submission error:', error);
                    const errorMessage = error.response?.data?.details || 'Failed to submit form. Please try again.';
                    setError(errorMessage);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        window.addEventListener('message', handleMessage);
        return () => window.removeEventListener('message', handleMessage);
    }, [backendAxios, url, onUrlChange]);

    const handleAddBlock = (position) => {
        if (!hoverElement || !selectedBlock) return;

        const newBlock = {
            id: Date.now(),
            type: selectedBlock.type,
            position,
            selector: hoverElement,
            content: '',
        };

        setBlocks(prevBlocks => [...prevBlocks, newBlock]);
    };

    const handleBlockChange = (blockId, content) => {
        setBlocks(prevBlocks =>
            prevBlocks.map(block =>
                block._id === blockId ? { ...block, content } : block
            )
        );
    };

    const renderBlock = (block) => {
        switch (block.type) {
            case 'text':
                return (
                    <TextBlock
                        key={block._id}
                        initialContent={block.content}
                        onChange={(content) => handleBlockChange(block._id, content)}
                    />
                );
            case 'image':
                return (
                    <ImageBlock
                        key={block._id}
                        initialImage={block.content}
                        onChange={(content) => handleBlockChange(block._id, content)}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <EditorContainer>
            <IframeContainer>
                <StyledIframe 
                    ref={iframeRef} 
                    sandbox="allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation" 
                />
                {isLoading && (
                    <LoadingOverlay>
                        Loading content...
                    </LoadingOverlay>
                )}
                {error && (
                    <ErrorMessage>
                        {error}
                    </ErrorMessage>
                )}
                {success && (
                    <SuccessMessage>
                        {success}
                    </SuccessMessage>
                )}
                <BlocksContainer>
                    {blocks.map(renderBlock)}
                </BlocksContainer>
                {hoverElement && selectedBlock && !isLoading && (
                    <>
                        <HoverOverlay style={overlay} />
                        <ActionButton className="top" onClick={() => handleAddBlock('top')}>
                            Add Block Above
                        </ActionButton>
                        <ActionButton className="bottom" onClick={() => handleAddBlock('bottom')}>
                            Add Block Below
                        </ActionButton>
                    </>
                )}
            </IframeContainer>
        </EditorContainer>
    );
};

export default VisualEditor; 