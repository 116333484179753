import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./SimpleEditor.css";
import { Tag } from 'antd';
import { useRef, useEffect } from 'react';

const SimpleEditor = ({ initialContent = '', onChange, placeholder = 'Start typing...' }) => {
    const [content, setContent] = useState(initialContent);
    const [isFocused, setIsFocused] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(null);
    const quillRef = useRef(null);
    const [displayContent, setDisplayContent] = useState('');

    const [highlightWords, setHighlightWords] = useState([
        { text: 'sugar', color: 'blue', range: { start: -1, end: -1 } },
        { text: 'calories', color: 'red', range: { start: -1, end: -1 } },
        { text: 'dates', color: 'yellow', range: { start: -1, end: -1 } },
        { text: 'celebrate', color: 'green', range: { start: -1, end: -1 } }
    ]);

    const handleChange = (value, delta, source, editor) => {
        setContent(value);
        if (onChange) {
            onChange(value);
        }
        handleCursorPosition(editor);
    };

    const handleCursorPosition = (editor) => {
        const selection = editor.getSelection();
        if (selection) {
            setCursorPosition(selection.index);
            // Check if cursor is between highlighted words
            const isBetweenHighlights = highlightWords.some((word, index) => {
                const currentWord = word.range;
                if (selection.index > currentWord.start && selection.index < currentWord.end) {
                    return true;
                }
                return false;
            });
            console.log('Cursor position:', selection.index);
            console.log('Is between highlights:', isBetweenHighlights);
        }
    }

    useEffect(() => {
        const editor = quillRef.current.getEditor();
        editor.on('selection-change', (range) => {
            if (range) {
                handleCursorPosition(editor);
            } else {
                setCursorPosition(null);
            }
        });
    }, []);

    useEffect(() => {
        // Update highlight word ranges based on content
        if (content) {
            const text = quillRef.current.getEditor().getText();
            const unprivilegedEditor = quillRef.current.makeUnprivilegedEditor(quillRef.current.getEditor());
            let displayText = unprivilegedEditor.getHTML();
            const updatedHighlightWords = highlightWords.map(word => {
                const wordText = word.text;
                const start = displayText.toLowerCase().indexOf(wordText.toLowerCase());

                if (start !== -1) {
                    return {
                        ...word,
                        range: {
                            start,
                            end: start + wordText.length
                        }
                    };
                }
                return word;
            });
            setHighlightWords(updatedHighlightWords);
            let highlightedContent = displayText;
            updatedHighlightWords
                .filter(word => word.range) // Filter out words without ranges first
                .sort((a, b) => b.range.start - a.range.start) // Process from end to start to maintain indices
                .forEach(word => {
                    const { start, end } = word.range;
                    highlightedContent =
                        highlightedContent.slice(0, start) +
                        `<span class="highlight" style="background-color: ${word.color}">` +
                        highlightedContent.slice(start, end) +
                        '</span>' +
                        highlightedContent.slice(end);
                });
            highlightedContent = highlightedContent.slice(0, highlightedContent.length - 1);
            setDisplayContent(highlightedContent);
        }
    }, [content]);

    return (
        <div className="editor-container">
            <div className={`quill-container ${isFocused ? 'visible' : 'hidden'}`}>
                <ReactQuill
                    value={content}
                    onChange={handleChange}
                    placeholder={placeholder}
                    theme="snow"
                    className={`editor-wrapper`}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    ref={quillRef}
                />
            </div>
            <div
                className={`highlight-view ${isFocused ? 'editing' : ''}`}
                dangerouslySetInnerHTML={{ __html: displayContent }}
                onClick={() => {
                    if (!isFocused) {
                        quillRef.current.focus();
                    }
                }}
            />
        </div>
    );
};

export default SimpleEditor;