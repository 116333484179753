import React from 'react';
import { Button, Row, Col } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import TextBlock from '../../molecules/TextBlock/TextBlock';
import AccordionBlock from '../../molecules/AccordionBlock/AccordionBlock';
import ContentConcerns from '../ContentConcerns/ContentConcerns';
import './ContentBlocks.css';

const ContentBlocks = ({ blocks, onUpdateBlock, onDeleteBlock, onMoveBlock, matchedConcerns }) => {
    return (
        <div className="content-blocks">
            {blocks.map((block) => (
                <Row>
                    <Col span={24}>
                        <div key={block._id} className="block-wrapper">
                            <div className="block-move-controls">
                                <Button
                                    type="text"

                                    icon={<CaretUpOutlined />}
                                    onClick={() => onMoveBlock(block._id, 'up')}
                                />
                                <Button

                                    type="text"
                                    icon={<CaretDownOutlined />}
                                    onClick={() => onMoveBlock(block._id, 'down')}
                                />
                            </div>

                            {block.type === 'text' ? (
                                <TextBlock
                                    key={block._id}
                                    content={block.content.text}
                                    onChange={(newContent) => onUpdateBlock(block._id, { text: newContent })}
                                    onDelete={() => onDeleteBlock(block._id)}
                                    matchedConcerns={matchedConcerns}

                                />
                            ) : (
                                    <AccordionBlock
                                    key={block._id}
                                    content={block.content}
                                    onChange={(newContent) => onUpdateBlock(block._id, newContent)}
                                    onDelete={() => onDeleteBlock(block._id)}
                                    matchedConcerns={matchedConcerns}

                                />
                            )}
                        </div>
                    </Col>
                    <Col span={8}>  
                        {block.concerns && (    
                            <ContentConcerns
                                concerns={block.concerns}
                            />
                        )}
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default ContentBlocks; 