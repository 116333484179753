import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Modal, Form, Input, Select, message, Card } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { useAuth } from '../../../provider/authProvider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const { Option } = Select;
const { TextArea } = Input;

const BrandVoices = () => {
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(null);
    const [brandVoices, setBrandVoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [generating, setGenerating] = useState(false);
    const [generatedVoice, setGeneratedVoice] = useState(null);
    const [form] = Form.useForm();
    const { backendAxios } = useAuth();

    // Fetch stores
    const fetchStores = async () => {
        try {
            const response = await backendAxios.get('super-admin/stores');
            setStores(response.data.stores);
        } catch (error) {
            message.error('Failed to fetch stores');
        }
    };

    // Fetch brand voices for selected store
    const fetchBrandVoices = async () => {
        if (!selectedStore) return;
        
        try {
            setLoading(true);
            const response = await backendAxios.get('generative-ai/brand-voices', {
                params: { shop: selectedStore }
            });
            setBrandVoices(response.data.brandVoices || []);
        } catch (error) {
            message.error('Failed to fetch brand voices');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStores();
    }, []);

    useEffect(() => {
        if (selectedStore) {
            fetchBrandVoices();
        }
    }, [selectedStore]);

    const handleCreate = async (values) => {
        try {
            await backendAxios.post('super-admin/brand-voices', {
                shop: selectedStore,
                ...values
            });
            message.success('Brand voice created successfully');
            setCreateModalVisible(false);
            form.resetFields();
            fetchBrandVoices();
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to create brand voice');
        }
    };

    const handleCreateBrandVoice = async (values) => {
        try {
            setGenerating(true);
            const response = await backendAxios.post('generative-ai/brand-voice/generate', {
                name: values.name,
                tags: values.tags,
                sourceCopy: values.sourceCopy ? values.sourceCopy.split('\n').filter(line => line.trim()) : [],
            }, {
                params: {
                    shop: selectedStore
                }
            });
            setGeneratedVoice(response.data.brandVoice);
            message.success('Brand voice generated successfully');
        } catch (err) {
            message.error(err.response?.data?.message || 'Failed to generate brand voice');
        } finally {
            setGenerating(false);
        }
    };

    const handleEdit = async (values) => {
        try {
            await backendAxios.put(`super-admin/brand-voices/${selectedVoice._id}`, values);
            message.success('Brand voice updated successfully');
            setEditModalVisible(false);
            form.resetFields();
            fetchBrandVoices();
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to update brand voice');
        }
    };

    const handleDelete = async (voiceId) => {
        try {
            await backendAxios.delete(`super-admin/brand-voices/${voiceId}`);
            message.success('Brand voice deleted successfully');
            fetchBrandVoices();
        } catch (error) {
            message.error(error.response?.data?.message || 'Failed to delete brand voice');
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (tags) => (
                tags ? tags.split(',').map(tag => (
                    <span key={tag.trim()} style={{ 
                        background: '#f0f0f0',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        marginRight: '4px'
                    }}>
                        {tag.trim()}
                    </span>
                )) : null
            )
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (date) => new Date(date).toLocaleDateString()
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button
                        icon={<EyeOutlined />}
                        onClick={() => {
                            setSelectedVoice(record);
                            setViewModalVisible(true);
                        }}
                    >
                        View
                    </Button>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                            setSelectedVoice(record);
                            form.setFieldsValue({
                                name: record.name,
                                tags: record.tags,
                                prompt: record.prompt
                            });
                            setEditModalVisible(true);
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        danger
                        onClick={() => handleDelete(record._id)}
                    >
                        Delete
                    </Button>
                </Space>
            )
        }
    ];

    const EditBrandVoiceForm = ({ onFinish, initialValues = {} }) => {
        return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={initialValues}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input placeholder="Enter brand voice name" />
            </Form.Item>
            <Form.Item
                name="tags"
                label="Tags"
                help="Separate tags with commas"
            >
                <Input placeholder="e.g. professional, friendly, casual" />
            </Form.Item>
            <Form.Item
                name="prompt"
                label="Prompt"
                rules={[{ required: true, message: 'Please enter the prompt' }]}
            >
                <ReactQuill
                    theme="snow"
                    style={{ height: '200px', marginBottom: '50px' }}
                    preserveWhitespace={true}
                />
            </Form.Item>
            <Form.Item>
                <Space>
                    <Button type="primary" htmlType="submit">
                        {initialValues.name ? 'Update' : 'Create'}
                    </Button>
                    <Button onClick={() => {
                        form.resetFields();
                        initialValues.name ? setEditModalVisible(false) : setCreateModalVisible(false);
                    }}>
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )};

    const CreateBrandVoiceForm = () => {
        return (
            <Form
                form={form}
                onFinish={handleCreateBrandVoice}
                layout="vertical"
            >
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please enter a name' }]}
                >
                    <Input placeholder="Enter brand voice name" />
                </Form.Item>

                <Form.Item
                    name="tags"
                    label="Tags"
                    help="Separate tags with commas"
                >
                    <Input placeholder="e.g. professional, friendly, casual" />
                </Form.Item>

                <Form.Item
                    name="sourceCopy"
                    label="Source Copy"
                    help="Enter sample content that represents your brand voice. Each line will be treated as a separate example."
                >
                    <ReactQuill
                        theme="snow"
                        style={{ height: '200px', marginBottom: '50px' }}
                        preserveWhitespace={true}
                    />
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button 
                            type="primary" 
                            htmlType="submit"
                            loading={generating}
                        >
                            Generate Brand Voice
                        </Button>
                        <Button onClick={() => {
                            form.resetFields();
                            setCreateModalVisible(false);
                            setGeneratedVoice(null);
                        }}>
                            Cancel
                        </Button>
                    </Space>
                </Form.Item>

                {generatedVoice && (
                    <Card title="Generated Brand Voice" style={{ marginTop: '24px' }}>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>Name:</strong> {generatedVoice.name}
                        </div>
                        <div style={{ marginBottom: '16px' }}>
                            <strong>Tags:</strong>{' '}
                            {generatedVoice.tags && generatedVoice.tags.split(',').map(tag => (
                                <span key={tag.trim()} style={{ 
                                    background: '#f0f0f0',
                                    padding: '2px 8px',
                                    borderRadius: '4px',
                                    marginRight: '4px'
                                }}>
                                    {tag.trim()}
                                </span>
                            ))}
                        </div>
                        <div>
                            <strong>Generated Prompt:</strong>
                            <pre style={{ 
                                whiteSpace: 'pre-wrap', 
                                fontFamily: 'inherit',
                                background: '#f5f5f5',
                                padding: '16px',
                                borderRadius: '4px',
                                marginTop: '8px'
                            }}>
                                {generatedVoice.prompt}
                            </pre>
                        </div>
                        <Button
                            type="primary"
                            style={{ marginTop: '16px' }}
                            onClick={() => {
                                handleCreate(generatedVoice);
                                setGeneratedVoice(null);
                            }}
                        >
                            Save Brand Voice
                        </Button>
                    </Card>
                )}
            </Form>
        );
    };

    return (
        <div style={{ padding: '24px' }}>
            <Card style={{ marginBottom: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Select
                        style={{ width: '300px' }}
                        placeholder="Select a store"
                        onChange={setSelectedStore}
                        value={selectedStore}
                    >
                        {stores.map(store => (
                            <Option key={store.shop} value={store.shop}>{store.shop}</Option>
                        ))}
                    </Select>
                    {selectedStore && (
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                form.resetFields();
                                setCreateModalVisible(true);
                            }}
                        >
                            Create Brand Voice
                        </Button>
                    )}
                </div>
            </Card>

            {selectedStore && (
                <Table
                    columns={columns}
                    dataSource={brandVoices}
                    loading={loading}
                    rowKey="_id"
                />
            )}

            {/* View Modal */}
            <Modal
                title={`Brand Voice: ${selectedVoice?.name}`}
                open={viewModalVisible}
                onCancel={() => {
                    setViewModalVisible(false);
                    setSelectedVoice(null);
                }}
                footer={[
                    <Button key="close" onClick={() => {
                        setViewModalVisible(false);
                        setSelectedVoice(null);
                    }}>
                        Close
                    </Button>
                ]}
                width={800}
            >
                {selectedVoice && (
                    <div>
                        <div style={{ marginBottom: 16 }}>
                            {selectedVoice.tags && selectedVoice.tags.split(',').map(tag => (
                                <span key={tag.trim()} style={{ 
                                    background: '#f0f0f0',
                                    padding: '2px 8px',
                                    borderRadius: '4px',
                                    marginRight: '4px'
                                }}>
                                    {tag.trim()}
                                </span>
                            ))}
                        </div>
                        <Card>
                            <pre style={{ whiteSpace: 'pre-wrap', fontFamily: 'inherit' }}>
                                {selectedVoice.prompt}
                            </pre>
                        </Card>
                    </div>
                )}
            </Modal>

            {/* Create Modal */}
            <Modal
                title="Create Brand Voice"
                open={createModalVisible}
                onCancel={() => {
                    setCreateModalVisible(false);
                    form.resetFields();
                    setGeneratedVoice(null);
                }}
                footer={null}
                width={800}
            >
                <CreateBrandVoiceForm />
            </Modal>

            {/* Edit Modal */}
            <Modal
                title="Edit Brand Voice"
                open={editModalVisible}
                onCancel={() => {
                    setEditModalVisible(false);
                    form.resetFields();
                }}
                footer={null}
                width={800}
            >
                <EditBrandVoiceForm 
                    onFinish={handleEdit}
                    initialValues={selectedVoice}
                />
            </Modal>
        </div>
    );
};

export default BrandVoices;