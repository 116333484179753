import React, { useState } from 'react';
import { Button, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { Text } = Typography;

const ConcernItem = ({ concern, onEdit }) => {
    const [isHovered, setIsHovered] = useState(false);

    const getStatusColor = (status) => {
        switch (status) {
            case 'resolved':
                return '#52c41a';
            case 'pending':
                return '#faad14';
            default:
                return '#d9d9d9';
        }
    };

    return (
        <div
            style={{
                padding: '12px',
                borderBottom: '1px solid #f0f0f0',
                cursor: 'pointer',
                background: isHovered ? '#fafafa' : 'white',
                position: 'relative',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', flex: 1 }}>
                    <div
                        style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            background: getStatusColor(concern.status),
                            marginTop: '8px'
                        }}
                    />
                    <Text style={{ flex: 1 }}>{concern.title}</Text>
                </div>
                <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => onEdit(concern)}
                    style={{ visibility: isHovered ? 'visible' : 'hidden' }}
                />
            </div>
            {isHovered && (
                <div style={{
                    marginTop: 8,
                    marginLeft: 16,
                    padding: 12,
                    background: '#f6ffed',
                    borderRadius: 4,
                    fontSize: '14px',
                    border: '1px solid #b7eb8f'
                }}>
                    {concern.description || 'No description provided yet'}
                </div>
            )}
        </div>

    );
};

export default ConcernItem; 