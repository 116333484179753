import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { LogoutOutlined, ApiOutlined, SoundOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Prompts from './Prompts';
import BrandVoices from './BrandVoices';

const { Header, Content, Sider } = Layout;

const SuperAdminDashboard = () => {
    const [selectedKey, setSelectedKey] = useState('prompts');
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('superAdminToken');
        navigate('/super-admin/login');
    };

    const menuItems = [
        {
            key: 'prompts',
            icon: <ApiOutlined />,
            label: 'Prompts'
        },
        // {
        //     key: 'brandVoices',
        //     icon: <SoundOutlined />,
        //     label: 'Brand Voices'
        // },
        {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: 'Logout',
            danger: true
        }
    ];

    const handleMenuClick = (e) => {
        if (e.key === 'logout') {
            handleLogout();
            return;
        }
        setSelectedKey(e.key);
    };

    const renderContent = () => {
        switch (selectedKey) {
            case 'prompts':
                return <Prompts />;
            // case 'brandVoices':
            //     return <BrandVoices />;
            default:
                return <Prompts />;
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                theme="light"
                breakpoint="lg"
                collapsedWidth="0"
            >
                <div style={{ 
                    height: '64px', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    borderBottom: '1px solid #f0f0f0'
                }}>
                    <h1 style={{ margin: 0, fontSize: '18px' }}>Super Admin</h1>
                </div>
                <Menu
                    mode="inline"
                    selectedKeys={[selectedKey]}
                    items={menuItems}
                    onClick={handleMenuClick}
                    style={{ borderRight: 0 }}
                />
            </Sider>
            <Layout>
                <Content style={{ margin: '0', background: '#fff', minHeight: 280 }}>
                    {renderContent()}
                </Content>
            </Layout>
        </Layout>
    );
};

export default SuperAdminDashboard; 