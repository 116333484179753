import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import SignInPage from "../components/pages/SignInPage";
import SignUpPage from "../components/pages/SignUpPage";
import Step1 from "../components/pages/Onboarding/Step1";
import Step2 from "../components/pages/Onboarding/Step2";
import { Navigate } from "react-router-dom";
import Dashboard from "../components/pages/LoggedInPages/Dashboard";
import SettingsBilling from "../components/pages/LoggedInPages/SettingsBilling";
import ProductCompare from "../components/pages/LoggedInPages/Products/ProductCompare";
import AllProducts from "../components/pages/LoggedInPages/Products/AllProducts";
import ProductEdit from "../components/pages/LoggedInPages/Products/ProductEdit";
import VisualEditorPage from "../components/pages/VisualEditorPage/VisualEditorPage";
import ImportProduct from "../components/pages/LoggedInPages/Products/ImportProduct";
import BrandVoice from "../components/pages/LoggedInPages/BrandAssets/BrandVoice";
import SuperAdminLogin from "../components/pages/SuperAdmin/Login";
import SuperAdminDashboard from "../components/pages/SuperAdmin/Dashboard";

const Routes = () => {
  const { token } = useAuth();

  // Define public routes accessible to all users
  const routesForPublic = [
    {
      path: "/",
      element: <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <SignInPage />,
    },
    {
      path: "/signup",
      element: <SignUpPage />,
    },
    {
      path: "/super-admin/login",
      element: <SuperAdminLogin />,
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/onboarding/step1",
          element: <Step1 />,
        },
        {
          path: "/onboarding/step2",
          element: <Step2 />,
        },
        {
          path: "/products/all",
          element: <AllProducts />,
        },
        {
          path: "/products/import",
          element: <ImportProduct />,
        },
        {
          path: "/brand-assets/voice",
          element: <BrandVoice />,
        },
        {
          path: "/settings/billing",
          element: <SettingsBilling />,
        },
        // {
        //   path: "/products/:productId",
        //   element: <ProductEdit />,
        // },
        {
          path: "/products/:productId/compare",
          element: <ProductCompare />,
        },
        {
          path: "/products/:productId/edit",
          element: <ProductEdit />,
        },
        {
          path: "/page-editor",
          element: <VisualEditorPage />,
        },
      ],
    },
  ];

  // Define routes accessible only to authenticated users
  const routesForSuperAdminOnly = [
    {
      path: "/",
      element: <ProtectedRoute requireSuperAdmin={true} />, // Wrap the component in ProtectedRoute
      children: [
        {
          path: "/super-admin/dashboard",
          element: <SuperAdminDashboard />,
        },
      ],
    },
  ];

  // Define routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly = [];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([
    ...routesForPublic,
    ...(!token ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
    ...routesForSuperAdminOnly,
  ]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
