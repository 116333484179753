import React, { useState } from 'react';
import { Button, Input, Collapse } from 'antd';
import { DeleteOutlined, DragOutlined, PlusOutlined } from '@ant-design/icons';
import SimpleEditor from '../SimpleEditor/SimpleEditor';
import './AccordionBlock.css';

const { Panel } = Collapse;

const AccordionBlock = ({ content, onChange, onDelete, defaultOpen = true }) => {
    const [items, setItems] = useState(content.items || []);
    const handleAddItem = () => {
        const newItems = [...items, { title: 'New Item', content: '' }];
        setItems(newItems);
        onChange({ ...content, items: newItems });
    };

    const handleItemChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index] = { ...newItems[index], [field]: value };
        setItems(newItems);
        onChange({ ...content, items: newItems });
    };

    const handleDeleteItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
        onChange({ ...content, items: newItems });
    };

    return (
        <div className="content-block accordion-block">
            <div className="block-controls">
                <Button type="text" icon={<DeleteOutlined />} onClick={onDelete} />
                <Button type="text" icon={<DragOutlined />} className="drag-handle" />
            </div>
            <Collapse accordion={false} defaultActiveKey={defaultOpen ? items.map((item, index) => index) : []}>
                {items.map((item, index) => (
                    <Panel
                        key={index}
                        header={
                            <Input
                                value={item.title}
                                onChange={(e) => handleItemChange(index, 'title', e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        extra={
                            <Button
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteItem(index);
                                }}
                            />
                        }
                    >
                        <SimpleEditor
                            initialContent={item.content}
                            onChange={(newContent) => handleItemChange(index, 'content', newContent)}
                        />
                    </Panel>
                ))}
            </Collapse>
            <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={handleAddItem}
                style={{ marginTop: 16, width: '100%' }}
            >
                Add Item
            </Button>
        </div>
    );
};

export default AccordionBlock; 