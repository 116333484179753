import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../provider/authProvider";
import { useSelector } from "react-redux";

export const ProtectedRoute = ({ requireSuperAdmin = false }) => {
  const { token } = useAuth();

  const user = useSelector((state) => state.user.userInfo);
  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }
  if (requireSuperAdmin && user?.role !== 'superadmin') {
    // If not a super admin, redirect to the home page
    return <Navigate to="/super-admin/login" />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
