import React, { useState } from 'react';
import styled from 'styled-components';
import VisualEditor from '../../organisms/VisualEditor/VisualEditor';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const Sidebar = styled.div`
  width: 300px;
  background: #f5f5f5;
  border-right: 1px solid #e0e0e0;
  padding: 20px;
  overflow-y: auto;
`;

const EditorContainer = styled.div`
  flex: 1;
  position: relative;
`;

const BlockOption = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background: #f0f0f0;
  }
  
  &.selected {
    background: #4B6BFB;
    color: white;
    border-color: #4B6BFB;
  }
`;

const UrlInput = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  
  &:focus {
    outline: none;
    border-color: #4B6BFB;
  }
`;

const VisualEditorPage = () => {
    const [url, setUrl] = useState('https://beena-oat-company.myshopify.com/products/chocolate-orange');
    const [selectedBlock, setSelectedBlock] = useState(null);

    const availableBlocks = [
        { id: 1, type: 'text', name: 'Text Block' },
        { id: 2, type: 'image', name: 'Image Block' }
    ];

    const handleBlockSelect = (block) => {
        setSelectedBlock(selectedBlock?._id === block._id ? null : block);
    };


    const handleUrlChange = (e) => {
        const newUrl = typeof e === 'string' ? e : e.target.value;
        setUrl(newUrl);
    };

    return (
        <PageContainer>
            <EditorContainer>
                <VisualEditor
                    url={url}
                    selectedBlock={selectedBlock}
                    onUrlChange={handleUrlChange}
                />
            </EditorContainer>
            <Sidebar>
                <UrlInput
                    type="url"
                    value={url}
                    onChange={handleUrlChange}
                    placeholder="Enter website URL"
                />
                <h3>Available Blocks</h3>
                {availableBlocks.map(block => (
                    <BlockOption
                        key={block._id}
                        className={selectedBlock?._id === block._id ? 'selected' : ''}
                        onClick={() => handleBlockSelect(block)}
                    >

                        {block.name}

                    </BlockOption>
                ))}
            </Sidebar>
        </PageContainer>
    );
};

export default VisualEditorPage; 