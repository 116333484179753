import React, { useState } from 'react';
import styled from 'styled-components';

const BlockContainer = styled.div`
  padding: 20px;
  margin: 10px 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  
  &:focus {
    outline: none;
    border-color: #4B6BFB;
  }
`;

const TextBlock = ({ initialContent = '', onChange }) => {
  const [content, setContent] = useState(initialContent);

  const handleChange = (e) => {
    setContent(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <BlockContainer>
      <TextArea
        value={content}
        onChange={handleChange}
        placeholder="Enter your text here..."
      />
    </BlockContainer>
  );
};

export default TextBlock; 