import axios from 'axios';
import exportConfig from '../config/config';

export const compareTexts = async (sourceText, destinationText) => {
    try {
        const response = await axios.post('/api/v1/compare-texts', {
            source_text: sourceText,
            destination_text: destinationText
        },{
            baseURL: exportConfig.diffCheckerBackendUrl
        });
        return response.data;
    } catch (error) {
        console.error('Error comparing texts:', error);
        throw error;
    }
}; 