import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Modal, Form, Input, Select, message, Card, Divider } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { useAuth } from '../../../provider/authProvider';
import ReactQuill from 'react-quill';
import axios from 'axios';

const { Option } = Select;
const { TextArea } = Input;

const Prompts = () => {
    const [promptTypes, setPromptTypes] = useState([]);
    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [copyModalVisible, setCopyModalVisible] = useState(false);
    const [createTypeModalVisible, setCreateTypeModalVisible] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [form] = Form.useForm();
    const [copyForm] = Form.useForm();
    const [typeForm] = Form.useForm();
    const [brandVoiceForm] = Form.useForm();
    const { backendAxios } = useAuth();
    const [selectedType, setSelectedType] = useState('');

    // const fetchStores = async () => {
    //     try {
    //         const response = await backendAxios.get('super-admin/stores');
    //         setStores(response.data.stores);
    //     } catch (error) {
    //         message.error('Failed to fetch stores');
    //     }
    // };

    const fetchPromptTypes = async () => {
        try {
            const response = await backendAxios.get('prompt-types');
            setPromptTypes(response.data);
        } catch (error) {
            message.error('Failed to fetch prompt types');
        }
    };

    const fetchPrompts = async () => {
        try {
            setLoading(true);
            const response = await backendAxios.get('super-admin/prompts');
            setPrompts(response.data.prompts);
            console.log(response.data.prompts);
            setLoading(false);
        } catch (error) {
            message.error('Failed to fetch prompts');
        } finally {
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchPromptTypes();
                await fetchPrompts();
            } catch (error) {
                message.error('Failed to fetch data');
            }
        };

        fetchData();
        // fetchStores();
        fetchPromptTypes();
    }, []);

    const handleEdit = (record) => {
        setSelectedPrompt(record);
        form.setFieldsValue({
            type: record.type,
            version: record.version,
            systemPrompt: record.systemPrompt,
            userPrompt: record.userPrompt,
            parameters: JSON.stringify(record.parameters, null, 2)
        });
        setEditModalVisible(true);
    };

    const handleDelete = async (record) => {
        try {
            await backendAxios.delete(`super-admin/prompts/${record._id}`);
            message.success('Prompt deleted successfully');
            fetchPrompts();
        } catch (error) {
            message.error('Failed to delete prompt');
        }
    };

    const handleActivate = async (record) => {
        try {
            await backendAxios.post(`super-admin/prompts/${record._id}/activate`);
            message.success('Prompt activated successfully');
            fetchPrompts();
        } catch (error) {
            message.error('Failed to activate prompt');
        }
    };

    const handleDeactivate = async (record) => {
        try {
            await backendAxios.post(`super-admin/prompts/${record._id}/deactivate`);
            message.success('Prompt deactivated successfully');
            fetchPrompts();
        } catch (error) {
            message.error('Failed to deactivate prompt');
        }
    };

    const handleEditSubmit = async (values) => {
        try {
            const data = {
                ...values,
                parameters: JSON.parse(values.parameters)
            };
            await backendAxios.put(`super-admin/prompts/${selectedPrompt._id}`, data);
            message.success('Prompt updated successfully');
            setEditModalVisible(false);
            form.resetFields();
            fetchPrompts();
        } catch (error) {
            message.error('Failed to update prompt');
        }
    };

    const handleCreateSubmit = async (values) => {
        try {
            const data = {
                ...values,
                parameters: JSON.parse(values.parameters)
            };
            await backendAxios.post('super-admin/prompts', data);
            message.success('Prompt created successfully');
            setCreateModalVisible(false);
            form.resetFields();
            fetchPrompts();
        } catch (error) {
            message.error('Failed to create prompt');
        }
    };

    const handleCopy = (record) => {
        setSelectedPrompt(record);
        copyForm.setFieldsValue({
            version: `${record.version}-copy`
        });
        setCopyModalVisible(true);
    };

    const handleCopySubmit = async (values) => {
        try {
            await backendAxios.post(`super-admin/prompts/${selectedPrompt._id}/copy`, values);
            message.success('Prompt copied successfully');
            setCopyModalVisible(false);
            copyForm.resetFields();
            fetchPrompts();
        } catch (error) {
            message.error('Failed to copy prompt');
        }
    };

    const handleCreateTypeSubmit = async (values) => {
        try {
            await backendAxios.post('prompt-types', values);
            message.success('Prompt type created successfully');
            setCreateTypeModalVisible(false);
            typeForm.resetFields();
            fetchPromptTypes();
        } catch (error) {
            message.error('Failed to create prompt type');
        }
    };

    const columns = [
        { title: 'Type', dataIndex: ['type', 'name'], key: 'type', render: (text, record) => record.type?.name || 'Unknown' },
        { title: 'Version', dataIndex: 'version', key: 'version' },
        { title: 'Active', dataIndex: 'isActive', key: 'isActive', render: (isActive) => isActive ? 'Yes' : 'No' },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => handleEdit(record)}
                    >
                        Edit
                    </Button>
                    <Button
                        icon={<CopyOutlined />}
                        onClick={() => handleCopy(record)}
                    >
                        Copy
                    </Button>
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete(record)}
                        danger
                    >
                        Delete
                    </Button>
                    {!record.isActive && (
                        <Button
                            type="primary"
                            onClick={() => handleActivate(record)}
                        >
                            Activate
                        </Button>
                    )}
                    {record.isActive && (
                        <Button
                            type="default"
                            onClick={() => handleDeactivate(record)}
                        >
                            Deactivate
                        </Button>
                    )}
                </Space>
            )
        }
    ];

    const PromptForm = ({ onFinish }) => (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
        >
            <Form.Item
                label="Type"
                name="type"
                rules={[{ required: true }]}
            >
                <Select>
                    {promptTypes.map(type => (
                        <Option key={type._id} value={type._id}>
                            {type.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="version"
                label="Version"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="systemPrompt"
                label="System Prompt"
                rules={[{ required: true }]}
            >
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item
                name="userPrompt"
                label="User Prompt"
                rules={[{ required: true }]}
            >
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item
                name="parameters"
                label="Parameters (JSON)"
                rules={[
                    { required: true },
                    {
                        validator: async (_, value) => {
                            try {
                                JSON.parse(value);
                            } catch (err) {
                                throw new Error('Invalid JSON format');
                            }
                        }
                    }
                ]}
                initialValue={JSON.stringify({
                    temperature: 0.7,
                    maxTokens: 1000
                }, null, 2)}
            >
                <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    {selectedPrompt ? 'Update' : 'Create'}
                </Button>
            </Form.Item>
        </Form>
    );

    return (
        <div style={{ padding: '24px' }}>
            <div style={{ marginBottom: '24px', display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
                <Button
                    type="default"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        typeForm.resetFields();
                        setCreateTypeModalVisible(true);
                    }}
                >
                    Create Prompt Type
                </Button>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        form.resetFields();
                        setCreateModalVisible(true);
                    }}
                >
                    Create Prompt
                </Button>
            </div>

            <Table
                columns={columns}
                dataSource={prompts}
                loading={loading}
                rowKey="_id"
            />

            <Modal
                title="Edit Prompt"
                open={editModalVisible}
                onCancel={() => {
                    setEditModalVisible(false);
                    form.resetFields();
                }}
                footer={null}
                width={800}
            >
                <PromptForm onFinish={handleEditSubmit} />
            </Modal>

            <Modal
                title="Create Prompt"
                open={createModalVisible}
                onCancel={() => {
                    setCreateModalVisible(false);
                    form.resetFields();
                }}
                footer={null}
                width={800}
            >
                <PromptForm onFinish={handleCreateSubmit} />
            </Modal>

            <Modal
                title="Copy Prompt"
                open={copyModalVisible}
                onCancel={() => {
                    setCopyModalVisible(false);
                    copyForm.resetFields();
                }}
                footer={null}
            >
                <Form
                    form={copyForm}
                    onFinish={handleCopySubmit}
                    layout="vertical"
                >
                    <Form.Item
                        name="version"
                        label="New Version"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Copy
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title="Create Prompt Type"
                open={createTypeModalVisible}
                onCancel={() => {
                    setCreateTypeModalVisible(false);
                    typeForm.resetFields();
                }}
                footer={null}
            >
                <Form
                    form={typeForm}
                    onFinish={handleCreateTypeSubmit}
                    layout="vertical"
                >
                    <Form.Item
                        name="code"
                        label="Code"
                        rules={[{ required: true, message: 'Please enter a code' }]}
                    >
                        <Input placeholder="Enter a unique code for the prompt type" />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter a name' }]}
                    >
                        <Input placeholder="Enter the prompt type name" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Create
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Prompts;