import React, { useEffect, useState } from "react";
import LoggedInPagesTemplate from "../../../templates/LoggedInPagesTemplate";
import { Row, Col, Card, Table, Button, Modal, Input, Space, message, Select } from "antd";
import { useAuth } from "../../../../provider/authProvider.js";
import { setUser } from "../../../../app/userSlice.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardTitle from "../../../atoms/Title";

const { TextArea } = Input;

const CategoryEditModal = ({ visible, onClose, sourceCopy, onSave, onGenerateNew }) => {
    const [editedCategory, setEditedCategory] = useState("");
    const [generatedCategories, setGeneratedCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible && sourceCopy) {
            setEditedCategory(sourceCopy.selectedCategory?.name || "");
            setGeneratedCategories(sourceCopy.generatedCategories || []);
        }
    }, [visible, sourceCopy]);

    const handleSave = () => {
        onSave({
            sourceId: sourceCopy._id,
            categories: [{ name: editedCategory }]
        });
    };

    return (
        <Modal
            title="Edit Category"
            open={visible}
            onCancel={onClose}
            footer={[
                <Button key="generate" onClick={onGenerateNew} loading={loading}>
                    Generate New Categories
                </Button>,
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                    Save
                </Button>
            ]}
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                <div>
                    <h4>Current Category</h4>
                    <Input
                        value={editedCategory}
                        onChange={(e) => setEditedCategory(e.target.value)}
                        placeholder="Enter category name"
                    />
                </div>
                {generatedCategories.length > 0 && (
                    <div>
                        <h4>Generated Categories</h4>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            {generatedCategories.map((category, index) => (
                                <Button
                                    key={index}
                                    block
                                    onClick={() => setEditedCategory(category)}
                                    style={{ textAlign: 'left' }}
                                >
                                    {category.name}
                                </Button>
                            ))}
                        </Space>
                    </div>
                )}
            </Space>
        </Modal>
    );
};

const GenerateModal = ({ visible, onClose, product, backendAxios, user }) => {
    const [promptTypes, setPromptTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [generatedContent, setGeneratedContent] = useState('');
    const [loading, setLoading] = useState(false);
    const [customPrompt, setCustomPrompt] = useState('');

    useEffect(() => {
        if (visible) {
            fetchPromptTypes();
        }
    }, [visible]);

    const fetchPromptTypes = async () => {
        try {
            const response = await backendAxios.get('prompt-types');
            setPromptTypes(response.data);
        } catch (error) {
            message.error('Failed to fetch prompt types');
        }
    };

    const handleGenerate = async () => {
        if (!selectedType) {
            message.error('Please select a prompt type');
            return;
        }

        try {
            setLoading(true);
            const response = await backendAxios.post('generative-ai/generate', {
                promptTypeCode: selectedType,
                productId: product._id,
                customPrompt: customPrompt || undefined,
                shop: user.store.shop
            });

            if (response.data.success) {
                const content = response.data.data.content;
                setGeneratedContent(
                    typeof content === 'object' ? JSON.stringify(content, null, 2) : content
                );
                message.success('Content generated successfully');
            }
        } catch (error) {
            message.error('Failed to generate content: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="Generate Content"
            open={visible}
            onCancel={onClose}
            width={800}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Close
                </Button>,
                <Button
                    key="generate"
                    type="primary"
                    onClick={handleGenerate}
                    loading={loading}
                >
                    Generate
                </Button>
            ]}
        >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <div>
                    <label>Prompt Type:</label>
                    <Select
                        style={{ width: '100%', marginTop: 8 }}
                        placeholder="Select a prompt type"
                        value={selectedType}
                        onChange={setSelectedType}
                    >
                        {promptTypes.map(type => (
                            <Select.Option key={type.code} value={type.code}>
                                {type.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>

                <div>
                    <label>Custom Prompt (Optional):</label>
                    <TextArea
                        rows={4}
                        value={customPrompt}
                        onChange={e => setCustomPrompt(e.target.value)}
                        placeholder="Enter a custom prompt if needed"
                        style={{ marginTop: 8 }}
                    />
                </div>

                {generatedContent && (
                    <div>
                        <label>Generated Content:</label>
                        <TextArea
                            rows={10}
                            value={generatedContent}
                            readOnly
                            style={{ marginTop: 8 }}
                        />
                    </div>
                )}
            </Space>
        </Modal>
    );
};

const AllProducts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { backendAxios } = useAuth();
    const user = useSelector((state) => state.user.userInfo);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedSourceCopy, setSelectedSourceCopy] = useState(null);
    const [categoryModalVisible, setCategoryModalVisible] = useState(false);
    const [generateModalVisible, setGenerateModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        if (user.store?.shop) {
            backendAxios.get(`/store/find?shop=${user.store.shop}`).then((x) => {
                const updatedUser = { ...user };
                updatedUser.store = x.data;
                dispatch(setUser(updatedUser));
            });

            // Fetch products
            backendAxios.get(`/products?shop=${user.store.shop}`)
                .then(response => response.data)
                .then(data => {
                    console.log(data);
                    setProducts(data.products);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Failed to fetch products:', error);
                    setLoading(false);
                });
        }
    }, [backendAxios, user.store?.shop]);

    const handleDelete = (id) => {
        // Implement delete functionality here
        console.log('Delete product with id:', id);
    };

    const handleGenerateCategory = async (productId) => {
        try {
            setLoading(true);
            const response = await backendAxios.post(`/generative-ai/generate-categories`, {
                productId,
                updateSelectedCategory: true
            });
            
            if (response.data.success) {
                // Refresh the products list
                const productsResponse = await backendAxios.get(`/products?shop=${user.store.shop}`);
                setProducts(productsResponse.data.products);
            }
        } catch (error) {
            console.error('Failed to generate category:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCategoryClick = (sourceCopies) => {
        if (sourceCopies && sourceCopies.length > 0) {
            setSelectedSourceCopy(sourceCopies[0]);
            setCategoryModalVisible(true);
        }
    };

    const handleSaveCategory = async (data) => {
        try {
            setLoading(true);
            const response = await backendAxios.post(`/generative-ai/select-categories/${data.sourceId}`, {
                categories: data.categories
            });
            
            if (response.data.success) {
                message.success('Category updated successfully');
                // Refresh the products list
                const productsResponse = await backendAxios.get(`/products?shop=${user.store.shop}`);
                setProducts(productsResponse.data.products);
            }
        } catch (error) {
            console.error('Failed to update category:', error);
            message.error('Failed to update category');
        } finally {
            setLoading(false);
            setCategoryModalVisible(false);
        }
    };

    const handleGenerateNewCategories = async () => {
        if (selectedSourceCopy) {
            await handleGenerateCategory(selectedSourceCopy.products[0]._id);
            setCategoryModalVisible(false);
        }
    };

    const handleGenerateClick = (product) => {
        setSelectedProduct(product);
        setGenerateModalVisible(true);
    };

    const productColumns = [
        {
            title: 'Product',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img
                        src={record.images[0]?.src || '/placeholder.png'}
                        alt={text}
                        style={{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '4px' }}
                    />
                    {text}
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'sourceCopies',
            key: 'sourceCopies',
            render: (sourceCopies) => (
                <div
                    onClick={() => handleCategoryClick(sourceCopies)}
                    style={{ cursor: 'pointer', color: '#1890ff' }}
                >
                    {sourceCopies.length > 0 ? sourceCopies[0].selectedCategory?.name : 'N/A'}
                </div>
            ),
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title: 'Lorem Ipsum',
            dataIndex: 'payment_type',
            key: 'payment_type',
            render: (text) => text || 'Transfer Bank',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let color;
                status = 'draft'
                switch (status?.toLowerCase()) {
                    case 'published':
                        color = '#4CAF50';
                        break;
                    case 'draft':
                        color = '#FFA500';
                        break;
                    case 'being generated':
                        color = '#FF0000';
                        break;
                    case 'delivered':
                        color = '#4CAF50';
                        break;
                    case 'cancelled':
                        color = '#FF0000';
                        break;
                    case 'process':
                        color = '#FFA500';
                        break;
                    default:
                        color = '#000000';
                }
                return (
                    <span style={{
                        color: color,
                        padding: '8px 12px',
                        borderRadius: '10px',
                        backgroundColor: `${color}10`
                        
                    }}>
                        Draft
                    </span>
                );
            },
        },
        {
            title: 'Action',
            key: 'actions',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: '8px' }}>
                    <Button
                        type="text"
                        icon={<span role="img" aria-label="edit">✏️</span>}
                        onClick={() => navigate(`/products/${record._id}/compare`)}
                    />
                    <Button
                        type="text"
                        icon={<span role="img" aria-label="category">🏷️</span>}
                        onClick={() => handleGenerateCategory(record._id)}
                    />
                    <Button
                        type="text"
                        icon={<span role="img" aria-label="generate">⚡</span>}
                        onClick={() => handleGenerateClick(record)}
                    />
                    <Button
                        type="text"
                        icon={<span role="img" aria-label="delete">🗑️</span>}
                        onClick={() => handleDelete(record._id)}
                    />
                </div>
            ),
        },
    ];

    return (
        <LoggedInPagesTemplate>
            <div style={{ padding: "24px" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <span>Show</span>
                        <select style={{ padding: '4px 8px', borderRadius: '4px', border: '1px solid #d9d9d9' }}>
                            <option>10</option>
                            <option>20</option>
                            <option>50</option>
                        </select>
                        <span>products</span>
                    </div>
                    <Button type="primary" style={{ backgroundColor: '#6366F1' }}>
                        Bulk Action
                    </Button>
                </div>
                <Card style={{ marginTop: "16px", backgroundColor: "#FFFFFF" }}>
                    <Table
                        loading={loading}
                        columns={productColumns}
                        dataSource={products}
                        rowKey="id"
                        pagination={{
                            total: products.length,
                            pageSize: 10,
                            showSizeChanger: false,
                        }}
                    />
                </Card>
                <CategoryEditModal
                    visible={categoryModalVisible}
                    onClose={() => setCategoryModalVisible(false)}
                    sourceCopy={selectedSourceCopy}
                    onSave={handleSaveCategory}
                    onGenerateNew={handleGenerateNewCategories}
                />
                <GenerateModal
                    visible={generateModalVisible}
                    onClose={() => setGenerateModalVisible(false)}
                    product={selectedProduct}
                    backendAxios={backendAxios}
                    user={user}
                />
            </div>
        </LoggedInPagesTemplate>
    );
};

export default AllProducts;
