import React from "react";
import { Row, Col } from "antd";
import PlatformCard from "../molecules/PlatformCard";
import Button from "../atoms/Button";
import OnboardingStep from "../templates/OnboardingStep";
import { useSelector } from "react-redux";
import config from "../../config/config.js";
import { useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title.js";
const platforms = [
  {
    name: "Shopify",
    imgSrc: "/webapp/shopify.png",
    link: "https://apps.shopify.com/beena-ai",
  },
  { name: "WooCommerce", imgSrc: "/webapp/woocommerce.png", link: "#" },
  { name: "Magento", imgSrc: "/webapp/magento.png", link: "#" },
  { name: "Square", imgSrc: "/webapp/square.png", link: "#" },
  { name: "PrestaShop", imgSrc: "/webapp/prestashop.png", link: "#" },
  { name: "Wix", imgSrc: "/webapp/wix.png", link: "#" },
  { name: "Other", imgSrc: "", link: "#" },
];
const availablePlatforms = ["Shopify"];
const PlatformSelectionForm = ({ reqStore, setReqStore }) => {
  const user = useSelector((state) => state.user.userInfo);
  console.log(user);
  const navigate = useNavigate();
  const handlePlatformClick = (platform) => {
    if (platform.link === "#") {
      alert("This feature is not yet available!");
    } else {
      if (user.email != config.testEmail) {
        // window.location.href = platform.link;
        // window.location.href = "/dashboard";
        setReqStore(platform.name);
      } else {
        navigate("/onboarding/step2");
      }
    }
  };
  return (
    <OnboardingStep
      title={
        user
          ? `Let's get you started, ${user.name || user.email}`
          : "Let's get you started"
      }
      percent={50}
      subtitle="What's your eCommerce platform?"
      description="You will be redirected to our app URL for that platform when you select:"
      // footer={
      //   <div
      //     style={{
      //       display: "flex",
      //       flexDirection: "row",
      //       justifyContent: "flex-end",
      //       marginTop: "20px",
      //     }}
      //   >
      //     <Button type="default" style={{ height: "50px" }}>
      //       Other
      //     </Button>
      //   </div>
      // }
    >
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        {platforms
          .filter((platform) => availablePlatforms.includes(platform.name))
          .map((platform) => (
            <Col span={8} key={platform.name}>
              <PlatformCard
                imgSrc={platform.imgSrc}
                name={platform.name}
                onClick={() => handlePlatformClick(platform)}
              />
            </Col>
          ))}
      </Row>
      <Title level={4}>Coming Soon</Title>
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        {platforms
          .filter((platform) => !availablePlatforms.includes(platform.name))
          .map((platform) => (
            <Col span={8} key={platform.name}>
              <PlatformCard
                imgSrc={platform.imgSrc}
                name={platform.name}
                onClick={() => handlePlatformClick(platform)}
                disabled={true}
              />
            </Col>
          ))}
      </Row>
    </OnboardingStep>
  );
};

export default PlatformSelectionForm;
