import React from 'react';
import { Button } from 'antd';
import { EditOutlined, UnorderedListOutlined } from '@ant-design/icons';

const BlockToolbar = ({ onAddBlock }) => (
    <div className="block-toolbar">
        <Button.Group>
            <Button
                icon={<EditOutlined />}
                onClick={() => onAddBlock('text')}
            >
                Add Text
            </Button>
            <Button
                icon={<UnorderedListOutlined />}
                onClick={() => onAddBlock('accordion')}
            >
                Add Accordion
            </Button>
        </Button.Group>
    </div>
);

export default BlockToolbar; 