import React, { useState } from 'react';
import { Steps, Form, Input, Button, Typography, Card, Space } from 'antd';
import LoggedInPagesTemplate from '../../../templates/LoggedInPagesTemplate';
import { message } from 'antd';
const { Title } = Typography;
const { TextArea } = Input;

const steps = ['Compare', 'Review', 'Missing Information', 'Fact Check'];

const ImportProduct = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    aliexpressUrl: '',
    shopifyUrl: '',
    additionalInfo: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    // TODO: Implement the import logic
    if (Array.from(Object.values(formData)).every(value => value.trim() == '')) {
      message.error('Please fill in any field');
      return;
    }

  };

  return (
    <LoggedInPagesTemplate>
      <Card style={{ marginTop: '24px' }}>
        <Title level={5}>Add a New Product</Title>

        <Steps current={activeStep} style={{ marginBottom: '24px' }}>
          {steps.map(label => (
            <Steps.Step key={label} title={label} />
          ))}
        </Steps>

        <Form onFinish={handleSubmit}>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Form.Item
              label="Source Copy URL"
            >
              <Input
                name="aliexpressUrl"
                placeholder="AliExpress URL"
                value={formData.aliexpressUrl}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Shopify URL"
            >
              <Input
                name="shopifyUrl"
                placeholder="Shopify URL (if exists)"
                value={formData.shopifyUrl}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item
              label="Additional Information"
            >
              <TextArea
                name="additionalInfo"
                placeholder="Optional"
                value={formData.additionalInfo}
                onChange={handleChange}
                rows={4}
              />
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="primary"
                htmlType="submit"
              >
                Import Product
              </Button>
            </div>
          </Space>
        </Form>
      </Card>
    </LoggedInPagesTemplate>
  );
};

export default ImportProduct;