import React from 'react';
import { Steps } from 'antd';

const steps = [
  {
    title: 'Compare',
    description: 'Review changes',
  },
  {
    title: 'Review',
    description: 'Check content',
  },
  {
    title: 'Missing Information',
    description: 'Fill gaps',
  },
  {
    title: 'Fact Check',
    description: 'Verify details',
  },
];

const ProductSteps = ({ current = 0 }) => {
  return (
    <Steps
      current={current}
      items={steps}
      style={{ maxWidth: 800, margin: '0 auto 24px' }}
    />
  );
};

export default ProductSteps; 