import React, { useState, useRef } from 'react';
import styled from 'styled-components';

const BlockContainer = styled.div`
  padding: 20px;
  margin: 10px 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border: 2px dashed #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  
  &:hover .overlay {
    opacity: 1;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
`;

const UploadOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
`;

const UploadButton = styled.button`
  padding: 10px 20px;
  background: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  
  &:hover {
    background: #f0f0f0;
  }
`;

const ImageBlock = ({ initialImage = '', onChange }) => {
  const [image, setImage] = useState(initialImage);
  const fileInputRef = useRef(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageData = e.target.result;
        setImage(imageData);
        if (onChange) {
          onChange(imageData);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <BlockContainer>
      <ImageContainer onClick={handleClick}>
        {image ? (
          <Image src={image} alt="Uploaded content" />
        ) : (
          <UploadButton>Upload Image</UploadButton>
        )}
        <UploadOverlay className="overlay">
          <UploadButton>Change Image</UploadButton>
        </UploadOverlay>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept="image/*"
          style={{ display: 'none' }}
        />
      </ImageContainer>
    </BlockContainer>
  );
};

export default ImageBlock; 