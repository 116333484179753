import React, { useEffect, useState } from "react";
import OnboardingTemplate from "../../templates/OnboardingTemplate";
import PlatformSelectionForm from "../../organisms/PlatformSelectionForm";
import { useAuth } from "../../../provider/authProvider";
import { useSelector } from "react-redux";
import { Button } from "antd";

const Step2 = () => {
  const user = useSelector((state) => state.user.userInfo);
  const [confirmationUrl, setConfirmationUrl] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const billingActive = queryParams.get("billingActive");
  const { backendAxios } = useAuth();
  useEffect(() => {
    if (!user.store.isBillingActive && !billingActive) {
      backendAxios
        .post("../../shopify/api/billing/get-charge-link", {
          storeId: user.store._id,
        })
        .then((x) => {
          setConfirmationUrl(x.data.confirmationUrl);
        });
    }
  }, [user.store.isBillingActive, billingActive]);
  return (
    <OnboardingTemplate
      leftCol={
        <Button href={confirmationUrl}>
          {confirmationUrl ? "Activate Billing" : "Loading..."}
        </Button>
      }
      rightCol={<></>}
    />
  );
};

export default Step2;
