import React from 'react';
import { Button, Tag } from 'antd';
import { Typography } from 'antd';

const { Text } = Typography;

const VersionItem = ({ version, isCurrentVersion, onPublish }) => (
    <div className={`version-item ${isCurrentVersion ? 'current' : ''}`}>
        <div className="version-info">
            <div className="version-number">
                Version {version.version}
                {version.status === 'published' && (
                    <Tag color="green" style={{ marginLeft: 8 }}>Published</Tag>
                )}
                {version.status === 'draft' && (
                    <Tag color="orange" style={{ marginLeft: 8 }}>Draft</Tag>
                )}
            </div>
            <div className="version-date">
                {new Date(version.createdAt).toLocaleString()}
            </div>
        </div>
        <div className="version-actions">
            {version.status === 'draft' && (
                <Button 
                    type="primary" 
                    size="small"
                    onClick={() => onPublish(version._id)}
                >
                    Publish
                </Button>
            )}
        </div>
    </div>
);

export default VersionItem; 