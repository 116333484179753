import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout, Card, Typography, Spin, message, Empty, Row, Col } from "antd";
import LoggedInPagesTemplate from "../../../templates/LoggedInPagesTemplate.js";
import ProductSteps from "../../../molecules/ProductSteps";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../provider/authProvider.js";
import { useSelector } from "react-redux";
import { Button } from "antd";
const { Content } = Layout;

const ProductCompare = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(false);
  // const [comparison, setComparison] = useState(null);
  const [beforeDescription, setBeforeDescription] = useState(null);
  const [afterDescription, setAfterDescription] = useState(null);
  const { backendAxios } = useAuth();
  const user = useSelector((state) => state.user.userInfo);
  const shop = user.store?.shop;
  const navigate = useNavigate();

  useEffect(() => {
    fetchComparison();
  }, []);

  const fetchComparison = async () => {
    setLoading(true);
    try {
      const response = await backendAxios.get(`/products/${productId}/descriptions?shop=${shop}`);
      setBeforeDescription(response.data.find(version => version.status === 'published'));
      setAfterDescription(response.data[0]);
    } catch (error) {
      message.error("Failed to fetch description versions");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNew = async () => {
    setLoading(true);
    try {
      const response = await backendAxios.post(`/generative-ai/generate-description/${beforeDescription.sourceCopies[0]}`, {
        shop,
        productId,
      });
      setAfterDescription(response.data.description);
      message.success("Generated new description");
    } catch (error) {
      message.error("Failed to generate new description");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <LoggedInPagesTemplate>
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Spin size="large" />
        </div>
      </LoggedInPagesTemplate>
    );
  }

  return (
    <LoggedInPagesTemplate>
      <Content>
        <Row>
          <Col span={20}>
            <div style={{
              background: '#fff',
              padding: '24px',
              borderBottom: '1px solid #f0f0f0'
            }}>
              <ProductSteps current={0} />
            </div>
          </Col>
          <Col span={4}>
            <Button
              type="primary"
              size="large"
              style={{ float: 'right', marginRight: 24 }}
              onClick={() => navigate(`/products/${productId}/edit`)}
            >
              Next
            </Button>
          </Col>
        </Row>

        <div style={{ padding: '24px', maxWidth: 1200, margin: '0 auto' }}>
          {beforeDescription && afterDescription ? (
            <Row gutter={24}>
              <Col span={12}>
                <Card
                  title={<div style={{ color: '#666' }}>Before</div>}
                  bordered={false}
                  style={{
                    height: '100%',
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: beforeDescription.description }} />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  title={
                    <div style={{ color: '#666', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span>After</span>
                      <Button type="primary" onClick={handleGenerateNew}>
                        Generate New
                      </Button>
                    </div>
                  }
                  bordered={false}
                  style={{
                    height: '100%',
                    borderColor: '#b7eb8f'
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: afterDescription.description }} />
                </Card>
              </Col>
            </Row>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No version history available for comparison"
            />
          )}
        </div>
      </Content>
    </LoggedInPagesTemplate>
  );
};

export default ProductCompare;