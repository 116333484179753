import React from 'react';
import { Card } from 'antd';
import ConcernItem from '../../molecules/ConcernItem/ConcernItem';

const ContentConcerns = ({ concerns = [], onEditConcern, extra }) => {
    return (
        <Card
            title="Content Concerns"
            bordered={false}
            extra={extra}
        >
            {concerns.map((concern, index) => (
                <ConcernItem
                    key={index}
                    concern={concern}
                    onEdit={() => onEditConcern(concern)}
                />
            ))}
        </Card>
    );
};

export default ContentConcerns; 