import React from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import PlatformSelectionForm from "../../organisms/PlatformSelectionForm";
import { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { useAuth } from "../../../provider/authProvider";


const ImportStoreForm = () => {
  const { backendAxios } = useAuth();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await backendAxios.post('store', {
        url: values.storeUrl,
        name: values.storeName
      });
      message.success('Store imported successfully!');
      window.location.href = "/dashboard";
    } catch (error) {
      message.error('Failed to import store');
    }
    setLoading(false);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name="storeUrl"
        label="Store URL"
        rules={[{ required: true, message: 'Please input your store URL!' }]}
      >
        <Input placeholder="mystore.myshopify.com" />
      </Form.Item>

      <Form.Item
        name="storeName"
        label="Store Name"
        rules={[{ required: true, message: 'Please input your store name!' }]}
      >
        <Input placeholder="My Store" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Import Store
        </Button>
      </Form.Item>
    </Form>
  );
};

const Step1 = () => {
  const [reqStore, setReqStore] = useState(false);
  return (
    <OnboardingTemplate leftCol={<PlatformSelectionForm reqStore={reqStore} setReqStore={setReqStore} />} rightCol={<>
      {reqStore && <ImportStoreForm />}
    </>} />
  );
};

export default Step1;
