import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Row, Col, Card, Typography, Spin, message, Button } from 'antd';
import { useAuth } from "../../../../provider/authProvider";
import { useSelector } from "react-redux";
import LoggedInPagesTemplate from "../../../templates/LoggedInPagesTemplate";
import ProductSteps from "../../../molecules/ProductSteps";
import BlockToolbar from '../../../atoms/BlockToolbar/BlockToolbar';
import ContentBlocks from '../../../organisms/ContentBlocks/ContentBlocks';
import VersionControl from '../../../organisms/VersionControl/VersionControl';
import ContentConcerns from '../../../organisms/ContentConcerns/ContentConcerns';
import { DUMMY_CONCERNS } from './constants';
import './ProductEdit.css';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';
import { compareTexts } from '../../../../api/diffChecker';
import { current } from '@reduxjs/toolkit';

const { Content } = Layout;
const { Text } = Typography;

const ProductEdit = () => {
    const { productId } = useParams();
    const [loading, setLoading] = useState(false);
    const [concerns, setConcerns] = useState([]);
    const { backendAxios } = useAuth();
    const user = useSelector((state) => state.user.userInfo);
    const shop = user.store?.shop;
    const [blocks, setBlocks] = useState([]);
    const [versions, setVersions] = useState([]);
    const [currentVersion, setCurrentVersion] = useState(null);
    const [saving, setSaving] = useState(false);
    const [concernsMap, setConcernsMap] = useState({});
    const [matchedConcerns, setMatchedConcerns] = useState([]);

    useEffect(() => {
        if (productId && shop) {
            fetchContent()
        }
    }, [productId, shop]);

    const fetchContent = async () => {
        setLoading(true);
        try {
            const response = await backendAxios.post(`/products/${productId}/descriptions`, {
                shop
            });

            if (response.data && response.data.length > 0) {
                setVersions(response.data);
                const latestVersion = response.data[0];
                console.log(latestVersion);
                setCurrentVersion(latestVersion);
                setBlocks(latestVersion.blocks || []);
            }
        } catch (error) {
            message.error("Failed to fetch description");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const updateContent = useMemo(
        () => debounce(async (newBlocks) => {
            setSaving(true);
            try {
                const response = await backendAxios.put(`/products/${productId}/description`, {
                    shop,
                    description: newBlocks.map(block => block.content.text).join('\n'),
                    blocks: newBlocks
                });

                setVersions(response.data.allVersions);
                setCurrentVersion(response.data.allVersions[0]);
                message.success("Content saved successfully");
            } catch (error) {
                message.error("Failed to update description");
            } finally {
                setSaving(false);
            }
        }, 1000),
        [backendAxios, productId, shop]
    );


    const handleAddBlock = (type) => {
        const newBlock = {
            id: Date.now().toString(),
            type,
            content: type === 'text' ? { text: '' } : { title: '', items: [] },
            order: blocks.length
        };
        const newBlocks = [...blocks, newBlock];
        setBlocks(newBlocks);
        updateContent(newBlocks);
    };

    const handleUpdateBlock = (id, newContent) => {
        const newBlocks = blocks.map(block =>
            block._id === id ? { ...block, content: newContent } : block
        );
        setBlocks(newBlocks);
        updateContent(newBlocks);
    };

    const handleDeleteBlock = (id) => {
        const newBlocks = blocks.filter(block => block._id !== id);
        setBlocks(newBlocks);
        updateContent(newBlocks);
    };

    const handleMoveBlock = (id, direction) => {
        const currentIndex = blocks.findIndex(block => block._id === id);
        if (
            (direction === 'up' && currentIndex === 0) ||
            (direction === 'down' && currentIndex === blocks.length - 1)
        ) {
            return;
        }

        const newBlocks = [...blocks];
        const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
        const [movedBlock] = newBlocks.splice(currentIndex, 1);
        newBlocks.splice(targetIndex, 0, movedBlock);

        newBlocks.forEach((block, index) => {
            block.order = index;
        });

        setBlocks(newBlocks);
        updateContent(newBlocks);
    };

    const fetchConcerns = async () => {
        try {
            console.log(currentVersion);
            
            setConcerns(currentVersion.sourceCopies[0].concerns);

        } catch (error) {
            message.error("Failed to fetch concerns");
        }
    };
    useEffect(() => {
        if (currentVersion) {
            fetchConcerns();
        }
    }, [currentVersion]);
    // const debouncedCompare = useMemo(
    //     () => debounce(async (texts, questions) => {
    //         if (texts && questions) {
    //             const comparison = await compareTexts(questions, texts);
    //             setConcernsMap(comparison);
    //             setMatchedConcerns(comparison.matches);
    //         }
    //     }, 1000),
    //     []
    // );

    // useEffect(() => {
    //     const textBlocks = blocks.filter(block => block.type === 'text');
    //     const accordionBlocks = blocks.filter(block => block.type === 'accordion');

    //     const allTexts = textBlocks.map(block => block.content.text).join('\n') + '\n' + accordionBlocks.map(block => block.content.items.map(item => item.content).join('\n')).join('\n');
    //     const allQuestions = concerns.map(concern => concern.answer).join('\n');
    //     if (allTexts.trim() && allQuestions.trim()) {
    //         debouncedCompare(allTexts.trim(), allQuestions.trim());
    //     }

    //     return () => {
    //         debouncedCompare.cancel();
    //     };
    // }, [blocks, concerns, debouncedCompare]);

    useEffect(() => {

        let tmpBlocks = [];
        if (concernsMap?.matches?.length > 0) {
            for (const match of matchedConcerns) {
                // Text Block
                const textBlock = blocks.find(block => block.content.text?.includes(match.source_sentence));
                if (textBlock) {
                    if (!textBlock.hasOwnProperty('concerns')) {
                        textBlock.concerns = [];
                    }
                    textBlock.concerns.push(match.dest_index);
                    tmpBlocks.push(textBlock);
                }
                // Accordion Block
                for (const accordionBlock of blocks) {
                    if (accordionBlock.type === 'accordion') {
                        const accordionItem = accordionBlock.content.items.find(item => item.content?.includes(match.source_sentence));
                        if (accordionItem) {
                            if (!accordionItem.hasOwnProperty('concerns')) {
                                accordionItem.concerns = [];
                            }
                            accordionItem.concerns.push(match.dest_index);
                            tmpBlocks.push(accordionBlock);
                        }
                    }
                }
            }
        }
    }, [concernsMap]);

    const handleEditConcern = (concern) => {
        message.info(`Editing concern: ${concern.question}`);
    };

    const handleRegenerateConcerns = async () => {
        try {
            setLoading(true);
            const response = await backendAxios.post(`/generative-ai/generate-concerns`, {
                productId: productId,
            });
            
            if (response.data.success) {
                message.success('Concerns regenerated successfully');
                // Refresh the concerns
                await fetchConcerns();
            }
        } catch (error) {
            console.error('Failed to regenerate concerns:', error);
            message.error('Failed to regenerate concerns');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <LoggedInPagesTemplate>
                <div style={{ textAlign: "center", padding: "50px" }}>
                    <Spin size="large" />
                </div>
            </LoggedInPagesTemplate>
        );
    }

    return (
        <LoggedInPagesTemplate>
            <Content>
                <Row>
                    <Col span={20}>
                        <div style={{
                            background: '#fff',
                            padding: '24px',
                            borderBottom: '1px solid #f0f0f0'
                        }}>
                            <ProductSteps current={1} />
                        </div>
                    </Col>
                    <Col span={4}>
                        <Button
                            type="primary"
                            size="large"
                            style={{ float: 'right', marginRight: 24 }}
                        >
                            Next
                        </Button>
                    </Col>
                </Row>

                <Row >
                    <Col span={16}>
                        <Card
                            bordered={false}
                            style={{ height: '100%' }}
                            title={
                                <div style={{ color: '#666', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span>Content Blocks</span>
                                    {saving && <Text type="secondary">Saving...</Text>}
                                </div>
                            }
                            extra={<BlockToolbar onAddBlock={handleAddBlock} />}
                        >
                            <ContentBlocks
                                blocks={blocks}
                                matchedConcerns={matchedConcerns}
                                onUpdateBlock={handleUpdateBlock}
                                onDeleteBlock={handleDeleteBlock}
                                onMoveBlock={handleMoveBlock}
                            />
                        </Card>
                    </Col>
                    <Col span={8}>
                        {/* <Card
                                bordered={false}
                                style={{ marginBottom: 16 }}
                            >
                                <VersionControl
                                    versions={versions}
                                    currentVersion={currentVersion}
                                    onPublish={publishVersion}
                                    onCreateVersion={createNewVersion}
                                />
                            </Card> */}
                        <ContentConcerns
                            concerns={concerns}
                            onEditConcern={handleEditConcern}
                            // extra={
                            //     <Button 
                            //         onClick={handleRegenerateConcerns}
                            //         loading={loading}
                            //         icon={<span role="img" aria-label="regenerate">🔄</span>}
                            //     >
                            //         Regenerate
                            //     </Button>
                            // }
                        />
                    </Col>
                </Row>
            </Content>
        </LoggedInPagesTemplate>
    );
};

export default ProductEdit;
// // 
//     const createNewVersion = async () => {
//         try {
//             const response = await backendAxios.post(`/products/${productId}/description/version`, {
//                 shop
//             });

//             setVersions(response.data.allVersions);
//             setCurrentVersion(response.data.currentVersion);
//             message.success("New version created successfully");
//         } catch (error) {
//             message.error("Failed to create new version");
//         }
//     };

//     const publishVersion = async (versionId) => {
//         try {
//             const response = await backendAxios.post(
//                 `/products/${productId}/description/${versionId}/publish`,
//                 { shop }
//             );

//             setVersions(response.data.allVersions);
//             setCurrentVersion(response.data.currentVersion);
//             message.success("Version published successfully");
//         } catch (error) {
//             message.error("Failed to publish version");
//         }
//     };