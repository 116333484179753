import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import SimpleEditor from '../SimpleEditor/SimpleEditor';
import './TextBlock.css';

const TextBlock = ({ content, onChange, onDelete }) => {
    return (
        <div className="content-block text-block">
            <div className="block-controls">
                <Button type="text" icon={<DeleteOutlined />} onClick={onDelete} />
                <Button type="text" icon={<DragOutlined />} className="drag-handle" />
            </div>
            <SimpleEditor
                initialContent={content}
                onChange={onChange}
            />
        </div>
    );
};

export default TextBlock; 