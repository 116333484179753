import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../provider/authProvider';
import { useDispatch } from "react-redux";
import { setUser } from "../../../app/userSlice.js";

const SuperAdminLogin = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setToken, backendAxios } = useAuth();
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        try {
            setLoading(true);
            const res = await backendAxios.post('auth/super-admin/login', values);
            if (res.data.jwtToken) {
                setToken(res.data.jwtToken);
                console.log(res.data);
                dispatch(setUser(res.data));
                message.success('Login successful');
                navigate('/super-admin/dashboard');
            }
        } catch (error) {
            message.error('Invalid credentials');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ 
            height: '100vh', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            background: '#f0f2f5'
        }}>
            <Card title="Super Admin Login" style={{ width: 400 }}>
                <Form
                    name="super_admin_login"
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input 
                            prefix={<UserOutlined />} 
                            placeholder="Email" 
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="Password"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button 
                            type="primary" 
                            htmlType="submit" 
                            loading={loading}
                            block
                            size="large"
                        >
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default SuperAdminLogin; 